import React from 'react';
import styled from 'styled-components';
import _ from 'translations';
import { LinkedIn } from 'assets/icons';
import { DEVICES } from 'styles';
import { ContactForm } from './ContactForm';

interface IContactBox {
  isOpen: boolean;
  openBox: React.Dispatch<React.SetStateAction<boolean>>;
  displayPrivacyPolicy: () => void;
  mailOnly?: boolean;
}

export const ContactBox = ({
  isOpen,
  openBox,
  displayPrivacyPolicy,
  mailOnly,
}: IContactBox) => (
  <StyledContactBox mailOnly={mailOnly} className={isOpen ? '' : 'is-folded'}>
    <button
      type="button"
      className="button toggle"
      onClick={() => {
        openBox(!isOpen);
      }}
    >
      {_.contactBox.subtitle}
    </button>

    {mailOnly ? (
      <StyledEmail>
        <a href={`mailto:${_.privacyPolicy.emailAddress}`}>
          {_.privacyPolicy.emailAddress}
        </a>
      </StyledEmail>
    ) : (
      <div className="content">
        <ContactForm />
      </div>
    )}

    <footer className="footer">
      <button
        className="button button--small"
        type="button"
        onClick={displayPrivacyPolicy}
      >
        {_.contactBox.privacyPolicyLink}
      </button>
      <a className="social-icon" href={_.contactBox.linkedInLink}>
        <LinkedIn />
      </a>
    </footer>
  </StyledContactBox>
);

const StyledContactBox = styled.section<{ mailOnly?: boolean }>`
  width: 100%;
  min-height: 370px;
  position: fixed;
  bottom: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: var(--gradient);
  border-radius: 10px 10px 0 0;
  transition: transform 0.4s ease-out;

  &.is-folded {
    transform: ${(props) =>
      props.mailOnly ? 'translateY(70%)' : 'translateY(85%)'};
  }

  @media ${DEVICES.tablet} {
    width: 375px;
    right: 8%;
  }

  .content {
    flex-grow: 1;
    text-align: center;
    padding: 0 20px 30px;
  }

  .description {
    display: block;
    padding-bottom: 30px;
  }

  .toggle {
    padding: 20px 0;
    letter-spacing: -1px;
    text-transform: uppercase;
    align-self: stretch;
    text-align: center;
    cursor: pointer;
    outline: none;
  }

  .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px 30px;
  }

  .social-icon {
    svg {
      background: white;
      border-radius: 6px;
      fill: var(--black);
    }
  }
`;

const StyledEmail = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  padding: 0 20px 30px;
`;
