import React from 'react';
import styled from 'styled-components';

export const Input = ({ label, error, ...rest }) => (
  <StyledInput error={error}>
    <span className="label input-labels">{label}</span>
    <input {...rest} />
    <span className="assistive">{error}</span>
  </StyledInput>
);

const StyledInput = styled.div<{ error?: string }>`
  position: relative;
  padding-bottom: var(--input-spacer);

  .label {
    position: absolute;
    top: -10px;
    left: 15px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  .assistive {
    position: absolute;
    bottom: 5px;
    left: 15px;
    color: var(--error);
    font-size: 10px;
    letter-spacing: 0.25px;
  }

  input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px 4px 0px 0px;
    border-bottom: ${({ error }) =>
      `solid 2px var(${error ? '--error' : '--white'})`};

    &::placeholder {
      color: var(--black);
    }
  }
`;
