import { css } from 'styled-components';
import PoppinsMedium from '../assets/fonts/Poppins-Medium.ttf';
import PoppinsRegular from '../assets/fonts/Poppins-Regular.ttf';
import PoppinsSemiBold from '../assets/fonts/Poppins-SemiBold.ttf';
import LinetoMonoBold from '../assets/fonts/MonoWeb-Bold.woff';
import LinetoMonoBold2 from '../assets/fonts/MonoWeb-Bold.woff2';

const fontStyles = css`
  @font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url(${PoppinsRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: url(${PoppinsMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url(${PoppinsSemiBold}) format('truetype');
  }

  @font-face {
    font-family: 'Lineto Mono';
    font-weight: 600;
    src: url(${LinetoMonoBold2}) format('woff2');
    src: url(${LinetoMonoBold}) format('woff');
  }
`;

export default fontStyles;
