import React, { useEffect } from 'react';
import { PrimaryLogo, Cross } from 'assets/icons';
import styled from 'styled-components';
import { DEVICES } from 'styles';

interface IModal {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Modal = ({ isOpen, children, onClose = () => {} }: IModal) => {
  const handleKeyDown = ({ key }) => key === 'Escape' && onClose();

  useEffect(function addKeyListener() {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(function lockScroll() {
    window.scrollTo(0, 0);
    document.body.classList.add('lock-scroll');
    return () => {
      document.body.classList.remove('lock-scroll');
    };
  }, []);

  if (!isOpen) return null;

  return (
    <TransparentBackground>
      <Wrapper>
        <div className="logo">
          <PrimaryLogo />
        </div>
        <ModalContent>
          <Cross className="close-btn" onClick={onClose} />
          {children}
        </ModalContent>
      </Wrapper>
    </TransparentBackground>
  );
};

const TransparentBackground = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0 0 0 / 75%);
  padding: 40px 20px 20px;

  @media ${DEVICES.tablet} {
    padding-bottom: 80px;
  }
`;

const Wrapper = styled.div`
  max-width: var(--modal-width);
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .logo {
    text-align: center;
    padding-bottom: 40px;
  }
`;

const ModalContent = styled.div`
  position: relative;
  overflow: hidden;
  background: var(--greyLight);
  border-radius: 10px;
  box-shadow: 0px 0px 60px var(--teal);
  padding: 50px 20px 40px;

  @media ${DEVICES.tablet} {
    padding: 55px 30px 40px;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    fill: var(--black);
    cursor: pointer;
    /* make space around button clickable */
    box-sizing: content-box;
    padding: 5px;
  }
`;
