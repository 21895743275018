const translations = {
  comingSoon: {
    lead: 'Havo is the sustainable future of last-mile logistics.',
    subtitle: 'Launching soon',
  },
  privacyPolicy: {
    headline: 'Privacy Policy',
    subtitle: 'Last updated: 17.09.2021',
    fullAddress: 'full address',
    emailAddress: 'info@havo.global',
    postalAddress: 'San Francisco, CA',
  },
  contactBox: {
    subtitle: "Let's keep in touch",
    description:
      'Leave us your e-mail address, if you want to be up to date with Havo news.',
    fieldLabel: 'e-mail',
    fieldPlaceholder: 'Enter your e-mail',
    fieldError: 'provided email is incorrect',
    button: 'Keep me posted',
    privacyPolicyLink: 'Privacy Policy',
    linkedInLink: 'https://linkedin.com/company/havoinc',
    submitError: 'email cannot be saved. Try again later',
    successSubscription: 'thank You for subscribing',
  },
};

export default translations;

export const privacyPolicy = `
<p>[Havo, Inc.] [and its affiliates] ([collectively,] the “Company,” “we,” or “us”) wants you to be familiar with how we collect, use and disclose information. This Privacy Policy describes our practices in connection with information that we collect through the Website operated by us from which you are accessing this Privacy Policy (the “Website”).</p>
<hr/>

<h5>PERSONAL INFORMATION</h5>
<p>
Collection and Use of Personal Information
“Personal Information” is information that identifies you as an individual or relates to an identifiable individual. The Website only collects your email address when you sign up for our newsletter, and your IP address when you visit the Website, from which we may also derive your approximate location.
When you sign up for our newsletter, we will use your Personal Information to send you marketing related emails, with information about our services, new products and other news about our Company and the launch of the new website. We will do so based on your consent, which you have provided by signing up.
If you disclose any Personal Information relating to other people to us, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.

Disclosure of Personal Information
We disclose Personal Information to [our affiliates for the purposes described in this Privacy Policy and] our third party service providers, to facilitate the services they provide to us (such as website hosting and email delivery).
</p>
<hr/>

<h5>OTHER INFORMATION</h5>
<p>
“Other Information” is any information that does not reveal your specific identity or does not directly relate to an identifiable individual. The Website collects Other Information such as:

· Browser and device information

· Information that has been aggregated in a manner such that it no longer reveals your specific identity

Collection of Other Information

Certain information is collected by most browsers or automatically through your device, such as your Media Access Control (MAC) address, computer type (Windows or Mac), screen resolution, operating system name and version, device manufacturer and model, language, Internet browser type and version and the name and version of the Services (such as the App) you are using. We use this information to ensure that the Services function properly.

Uses and Disclosures of Other Information

We may use and disclose Other Information for any purpose, except where we are required to do otherwise under applicable law. If we are required to treat Other Information as Personal Information under applicable law, we may use and disclose it for the purposes for which we use and disclose Personal Information as detailed in this Policy. In some instances, we may combine Other Information with Personal Information. If we do, we will treat the combined information as Personal Information as long as it is combined.
</p>

<hr/>

<h5>SECURITY</h5>
<p>
We seek to use reasonable organizational, technical and administrative measures to protect Personal Information within our organization. Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us in accordance with the “Contacting Us” section below.
</p>

<hr/>

<h5>CHOICES AND ACCESS</h5>
<p>
Your choices regarding our use and disclosure of your Personal Information

You may at any time opt out from receiving marketing-related emails from us. If you no longer want to receive marketing related emails from us on a going-forward basis, you may opt out [by clicking the unsubscribe link at the bottom of our email] [NOTE: How can users opt out of marketing emails? Most marketing emails have an unsubscribe link included in the message. Will this also be the case for Havo? Can users also send an email to Havo to request to opt out?]

How you can access, change, or delete your Personal Information

If you would like to request to access, correct, update, suppress, restrict, or delete Personal Information, object to or opt out of the processing of Personal Information, or if you would like to request to receive a copy of your Personal Information for purposes of transmitting it to another company (to the extent these rights are provided to you by applicable law), you may contact us in accordance with the “Contacting Us” section below. We will respond to your request consistent with applicable law.

In your request, please make clear what Personal Information you would like to have changed or whether you would like to have your Personal Information suppressed from our database. For your protection, we may only implement requests with respect to the Personal Information associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request. We will try to comply with your request as soon as reasonably practicable.
</p>
<hr/>

<h5>RETENTION PERIOD</h5>
<p>
We retain Personal Information for as long as needed or permitted in light of the purpose(s) for which it was obtained and consistent with applicable law. For example, we will retain your email address for as long as you wish to receive marketing-related emails from us. We will delete your email address from our database once you ask us to, unless we have lawful reason for maintaining contact with you.
</p>
<hr/>

<h5>USE OF WEBSITE BY MINORS</h5>
<p>
The Website is not directed to individuals under the age of [sixteen (16)], and we do not knowingly collect Personal Information from individuals under 16. [NOTE: The minimum age differs by jurisdiction. Article 8 of GDPR provides that consent of a child below 16 years is not valid, although EU Member States may individually deviate down to 13 years (e.g., in Spain the minimum age is 14, and in the UK it is 13). 13 is the minimum age in the United States. Let’s discuss what minimum age to include here.]
</p>
<hr/>

<h5>JURISDICTION AND CROSS-BORDER TRANSFER</h5>
<p>
Your Personal Information may be stored and processed in any country where we have facilities or in which we engage service providers, and by using the Services you understand that your information will be transferred to countries outside of your country of residence, including the United States, which may have data protection rules that are different from those of your country. In certain circumstances, courts, law enforcement agencies, regulatory agencies or security authorities in those other countries may be entitled to access your Personal Information.
</p>
<hr/>

<h5>ADDITIONAL INFORMATION REGARDING THE EEA:</h5>
<p>
 Some non-EEA countries are recognized by the European Commission as providing an adequate level of data protection according to EEA standards (the full list of these countries is available here [hyperlink to EU Commission’s adequacy list online: https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en). For transfers from the EEA to countries not considered adequate by the European Commission, we have put in place adequate measures, such as standard contractual clauses adopted by the European Commission to protect your Personal Information. You may obtain a copy of these measures by contacting us in accordance with the “Contact Us” section below.
  </p>
  <hr/>

<h5>SENSITIVE INFORMATION</h5>
<p>
Unless we request it, we ask that you not send us, and you not disclose, any sensitive Personal Information (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background, or trade union membership) on or through the Website or otherwise to us.
</p>
<hr/>

<h5>UPDATES TO THIS PRIVACY POLICY</h5>

<p>
The “LAST UPDATED” legend at the top of this Privacy Policy indicates when this Privacy Policy was last revised. Any changes will become effective when we post the revised Privacy Policy on the Website. If we make a change to our Privacy Policy that warrants notifying you or requesting your consent, we may contact you. [NOTE: We recommend using this language here; however, if the company changes its policy in a material way it may be required to provide affected users with direct notice of the change and choice with respect to it.]
</p>
<hr/>


<h5>CONTACTING US</h5>
<p>
[Havo, Inc.] is the company responsible for collection, use, and disclosure of your Personal Information under this Privacy Policy.

If you have any questions about this Privacy Policy, please contact us at ${translations.privacyPolicy.emailAddress}.
Because email communications are not always secure, please do not include credit card or other sensitive information in your emails to us.
</p>
<hr/>

<h5>ADDITIONAL INFORMATION REGARDING THE EEA</h5>
<p>
You may also lodge a complaint with an EU/EEA data protection authority for your country or region where you have your habitual residence or place of work or where an alleged infringement of applicable data protection law occurs. A list of data protection authorities is available at http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080.'
</p>
`;
