import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

interface IButton {
  children: string;
  type: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

export const Button = ({
  children,
  disabled,
  type = 'button',
  ...rest
}: IButton) => (
  <StyledButton
    className={cn('button button--secondary ', {
      'button--disabled': disabled,
    })}
    type={type}
    {...rest}
  >
    {children}
  </StyledButton>
);

const StyledButton = styled.button`
  height: 50px;
  padding: 0 20px;
  border-radius: 4px;
  transition: box-shadow 0.4s;

  &.button--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.button--secondary {
    background: var(--black);
    color: var(--white);
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0px 4px 30px rgba(3, 124, 128, 0.5);
  }
`;
