import React from 'react';
import styled from 'styled-components';
import _, { privacyPolicy } from 'translations';

export const PrivacyPolicy = () => (
  <StyledPrivacyPolicy>
    <h4 className="header">{_.privacyPolicy.headline}</h4>
    <span className="overline">{_.privacyPolicy.subtitle}</span>
    <div
      className="content"
      dangerouslySetInnerHTML={{ __html: privacyPolicy }}
    />
  </StyledPrivacyPolicy>
);

const StyledPrivacyPolicy = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .header {
    color: var(--cyan);
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-wrap: break-word;
  }
`;
