import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'translations';
import { Input, Button } from 'components';
import { saveEmail } from 'actions/newsletterAPI';

const EMAIL_REGEXP = /^\S+@\S+\.\S+$/;

export const ContactForm = () => {
  const [emailValue, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const isValid = validateEmail(emailValue);

    if (!isValid) {
      setError(_.contactBox.fieldError);
      setLoading(false);
      return;
    }

    try {
      await saveEmail(emailValue);
      setError('');
      setSuccess(true);
    } catch (dbError) {
      setError(_.contactBox.submitError);
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => EMAIL_REGEXP.test(email);

  return success ? (
    <span className="description">{_.contactBox.successSubscription}</span>
  ) : (
    <StyledForm method="POST" noValidate onSubmit={handleSubmit}>
      <span className="description">{_.contactBox.description}</span>
      <fieldset disabled={isLoading} aria-busy={isLoading}>
        <Input
          value={emailValue}
          onChange={(event) => setEmail(event.target.value)}
          type="email"
          placeholder={_.contactBox.fieldPlaceholder}
          label={_.contactBox.fieldLabel}
          error={error}
        />
        <Button disabled={isLoading} type="submit">
          {_.contactBox.button}
        </Button>
      </fieldset>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  fieldset {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
`;
