import { createGlobalStyle } from 'styled-components';
import { normalize } from './normalize';
import fontStyles from './fontStyles';

const BREAKPOINTS = {
  mobile: 320,
  tablet: 461,
  desktop: 769,
};

export const DEVICES = {
  mobile: `(min-width: ${BREAKPOINTS.mobile}px)`,
  tablet: `(min-width: ${BREAKPOINTS.tablet}px)`,
  desktop: `(min-width: ${BREAKPOINTS.desktop}px)`,
};

// extend globals here
export const GlobalStyles = createGlobalStyle`
    ${normalize}

    ${fontStyles}

    :root {
      --grey: #E6E6E6;
      --greyLight: #FCFCFC;
      --cyan: #00B7BD;
      --cyan2: #61E4E8;
      --white: #FFFFFF;
      --black: #000000;
      --teal: #037C80;
      --teal2: #00797D;
      --error: #FF6738;
      --container-width: 1600px;
      --modal-width: 545px;
      --input-spacer: 25px;
      --gradient: linear-gradient(
        99.68deg,
        var(--cyan2) 0.15%,
        var(--teal2) 100.02%
      );
    }

    html {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      line-height: 22px;
      box-sizing: border-box;
    }

    *, *:before, *:after {
      box-sizing: inherit;
    }

    body {
      background: var(--black);

      &.lock-scroll {
      overflow: hidden;
     }
    }

    fieldset {
      border: none;
    }

    input {
      border: none;
      outline: none;
    }

    // typography
    h1, h2, h3, h4 {
      font-family: 'Lineto Mono';
      font-weight: 600;
      margin: 0;
    }

    h1 {
      font-size: 120px;
      line-height: 144px;
    }

    h2 {
      font-size: 56px;
      line-height: 64px;
    }

    h3 {
      font-size: 38px;
      line-height: 44px;
    }

    h4 {
      font-size: 24px;
      line-height: 32px;
    }

    h5 {
      font-weight: 600px;
      font-size: 18px;
      line-height: 24px;
    }

    .overline {
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .subtitle {
      font-size: 18px;
      line-height: 24px;
    }

    .caption {
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.25px;
    }

    .button {
      font-family: 'Lineto Mono';
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      text-transform: uppercase;
      background: none;
      border: none;
      cursor: pointer;

      &--small {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .input {
      padding: 0;
      font-family: 'Lineto Mono';
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      font-weight: 600;
    }

    .input-labels {
      font-family: 'Lineto Mono';
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      font-weight: 600;
    }

    // assets
    img {
      max-width: 100%;
    }

    svg {
      fill: var(--white)
    }
  `;
