import styled from 'styled-components';
import { DEVICES } from 'styles';

export const Hero = styled.div<{ img: string }>`
  height: 100vh;
  background: var(--black);
  background-image: ${(props) => `url(${props.img})`};

  background-size: 230%;
  background-position: bottom;
  background-repeat: no-repeat;

  @media ${DEVICES.tablet} {
    background-size: cover;
    background-position: center;
  }
`;

export const HeroGradient = styled.div`
  height: 100%;

  background: linear-gradient(
    var(--black) 55%,
    transparent 80%,
    transparent 55%,
    var(--black)
  );

  @media ${DEVICES.tablet} {
    background: linear-gradient(
      var(--black) 35%,
      transparent 60%,
      transparent 55%,
      var(--black)
    );
  }
`;
