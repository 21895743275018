import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import _ from 'translations';
import HeroImg from 'assets/images/cityscape-min.jpg';
import { PrimaryLogo, SecondaryLogo } from 'assets/icons';
import {
  ContactBox,
  Hero,
  HeroGradient,
  Layout,
  Modal,
  PrivacyPolicy,
} from 'components';

const ComingSoon = () => {
  const [isModalOpen, openModal] = useState(false);
  const [isBoxOpen, openBox] = useState(false);

  return (
    <Layout>
      <Hero img={HeroImg}>
        <HeroGradient>
          <Container>
            <StyledPrimaryLogo />
            <LogoHalo>
              <SecondaryLogo />
            </LogoHalo>
            <Lead>{_.comingSoon.lead}</Lead>
            <Subtitle>{_.comingSoon.subtitle}</Subtitle>
          </Container>
          <ContactBox
            isOpen={isBoxOpen}
            openBox={openBox}
            displayPrivacyPolicy={() => {
              openModal(true);
              openBox(false);
            }}
          />
        </HeroGradient>
      </Hero>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            openModal(false);
          }}
        >
          <PrivacyPolicy />
        </Modal>
      )}
    </Layout>
  );
};

export default ComingSoon;

const Container = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  margin: 0 auto;
  padding: 40px 20px 0;
`;

const Lead = styled.h4`
  text-align: center;
  color: var(--grey);
  padding: 40px 0 20px;
`;

const Subtitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  color: var(--cyan);
`;

const StyledPrimaryLogo = styled(PrimaryLogo)`
  margin-bottom: 100px;
`;

const heartBeat = keyframes`{
  from {
    opacity:0.6;
  }
  50% {
    opacity:0.9;
    animation-timing-function: ease-in;
  }
}`;

const HALO_OFFSET = '-15px';
const LogoHalo = styled.div`
  position: relative;

  svg {
    position: relative;
  }

  &::before {
    content: '';
    position: absolute;
    top: ${HALO_OFFSET};
    bottom: ${HALO_OFFSET};
    left: ${HALO_OFFSET};
    right: ${HALO_OFFSET};
    border-radius: 50%;
    background: var(--cyan2);
    opacity: 0.6;
    filter: blur(35px);
    animation: ${heartBeat} 4s ease-in-out infinite both;
  }
`;
