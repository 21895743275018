import React from 'react';
import { Helmet } from 'react-helmet';
import { GlobalStyles } from 'styles';

const SEO = {
  title: 'havo',
  description: 'Havo is the sustainable future of last-mile delivery.',
  image: '',
  canonicalUrl: '',
};

export const Layout = ({ children }) => {
  const { title, description, image, canonicalUrl } = SEO;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <link rel="canonical" href={canonicalUrl} />
        {/* favicons */}
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="alternate icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="any"
          href="/favicon.ico"
          color="#000000"
        />
        <link rel="mask-icon" sizes="any" href="/favicon.svg" color="#000000" />
      </Helmet>
      <GlobalStyles />
      {children}
    </>
  );
};
